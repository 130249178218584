






























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  CreateSmsMailingMutation,
  CreateSmsMailingMutationVariables,
  CreateEmailMailingListMutation,
  CreateEmailMailingListMutationVariables,
  AdresseeInput,
  User,
  Json,
} from "@/generated/graphql";
import * as rules from "@/core/validation";
import {
  createEmailMailingList,
  createSmsMailing,
} from "@/graphql/queries/Mailing.graphql";

@Component({})
export default class Mailing extends Vue {
  @Prop({ type: Array, required: true }) selected!: User[];

  public message: string = "";
  public eventName: string = "";
  public validityHours: number = 24;
  public validityMinutes: number = 0;
  public phoneNumbers: string[] = [];
  public validity: number = 1440;
  public adressees: AdresseeInput[] = [];
  public openMailForm: boolean = false;
  public openSMSForm: boolean = false;
  public phoneSnack: boolean = false;
  public mailSnack: boolean = false;
  public error: boolean = false;

  public readonly rules = rules;

  @Watch("selected", { deep: true })
  onSelected(selected: User[]): void {
    this.phoneNumbers = selected
      .filter((s) => s.phones)
      .reduce((previousValue: string[], currentValue: User): string[] => {
        const phones = currentValue?.phones
          //filter((p) => p.is_main)
          ?.reduce((previousValue: string[], currentValue: Json): string[] => {
            const value = currentValue?.value ? currentValue?.value : "";
            previousValue?.push(value);
            return previousValue;
          }, []);
        if (phones?.length) previousValue = [...previousValue, ...phones];
        return previousValue;
      }, []);
    this.adressees = selected
      .filter((s) => s.emails)
      .reduce((previousValue: any, currentValue: User): any => {
        const fullName = `${currentValue.family_name} ${currentValue.first_name} ${currentValue.second_name}`;
        const emails = currentValue?.emails
          //filter((p) => p.is_main)
          ?.reduce(
            (
              previousValue: AdresseeInput[],
              currentValue: Json
            ): AdresseeInput[] => {
              const email = currentValue?.value ? currentValue?.value : "";
              const address: AdresseeInput = {
                email,
                name: fullName,
              };
              previousValue?.push(address);
              return previousValue;
            },
            []
          );
        if (emails?.length) previousValue = [...previousValue, ...emails];
        return previousValue;
      }, []);
  }

  @Watch("validityHours")
  onHours(): void {
    this.validity = this.validityHours * 60 + +this.validityMinutes;
  }

  @Watch("validityMinutes")
  onMins(): void {
    this.validity = this.validityHours * 60 + +this.validityMinutes;
  }

  get disableBtns(): boolean {
    return !this.selected.length;
  }

  async mailList(): Promise<void> {
    if (!this.adressees.length) {
      this.mailSnack = true;
      return;
    }
    const input = {
      adressees: this.adressees,
      eventName: this.eventName,
    };
    try {
      await this.$apollo.mutate<
        CreateEmailMailingListMutation,
        CreateEmailMailingListMutationVariables
      >({
        mutation: createEmailMailingList,
        variables: { input },
      });
    } catch (error) {
      console.error(error);
      this.error = true;
    } finally {
      this.openMailForm = false;
    }
  }

  async SMSlist(): Promise<void> {
    if (!this.phoneNumbers.length) {
      this.phoneSnack = true;
      return;
    }
    const input = {
      phoneNumbers: this.phoneNumbers,
      message: this.message,
      validity: this.validity,
    };
    try {
      await this.$apollo.mutate<
        CreateSmsMailingMutation,
        CreateSmsMailingMutationVariables
      >({
        mutation: createSmsMailing,
        variables: { input },
      });
    } catch (error) {
      console.error(error);
      this.error = true;
    } finally {
      this.openSMSForm = false;
    }
  }
}
