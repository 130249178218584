






















import { Component, Vue, VModel, Prop } from "vue-property-decorator";
import { DoctorAssociation } from "@/generated/graphql";
import { AllDoctorAssociations } from "@/graphql/queries/DoctorsAssociations.graphql";

@Component
export default class AssociationsAutocomplete extends Vue {
  @Prop({ type: Boolean, default: false }) employees!: boolean;

  @VModel({ type: [String, Array] }) model!:
    | DoctorAssociation
    | DoctorAssociation[];

  protected search: string = "";
  protected query = AllDoctorAssociations;
}
