






















import { Component, Vue, VModel, Prop } from "vue-property-decorator";
import { DoctorWorkplace } from "@/generated/graphql";
import { AllDoctorWorkplaces } from "@/graphql/queries/DoctorsWorkplaces.graphql";

@Component
export default class WorkplacesAutocomplete extends Vue {
  @Prop({ type: Boolean, default: false }) employees!: boolean;

  @VModel({ type: [String, Array] }) model!:
    | DoctorWorkplace
    | DoctorWorkplace[];

  protected search: string = "";
  protected query = AllDoctorWorkplaces;
}
