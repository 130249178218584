






















import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { Debounce } from "vue-debounce-decorator";
import {
  CitiesQueryVariables,
  City,
  CityPaginator,
  QueryCitiesWhereColumn,
  SqlOperator,
} from "@/generated/graphql";
import Card from "@components/page/Card.vue";
import { Cities } from "@/graphql/queries/Cities.graphql";

@Component({
  components: { Card },
  apollo: {
    cities: {
      query: Cities,
      variables(): CitiesQueryVariables {
        return {
          page: 1,
        };
      },
    },
  },
})
export default class ServerCityAutocomplete extends Vue {
  @VModel({ type: [Object, Array] }) items!: City;

  protected searchValue = "";
  protected cities: CityPaginator = {
    paginatorInfo: {
      count: 0,
      currentPage: 1,
      perPage: 10,
      hasMorePages: true,
      lastPage: 0,
      total: 0,
    },
    data: [],
  };

  protected fetchMoreUsers(): void {
    this.$apollo.queries.users.fetchMore({
      //@ts-ignore
      variables: {
        ...this.fetchVariables,
        page: this.cities.paginatorInfo.currentPage++,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          users: {
            __typename: fetchMoreResult.users.__typename,
            paginatorInfo: fetchMoreResult.users.paginatorInfo,
            data: [...this.cities.data, ...fetchMoreResult.users.data],
          },
        };
      },
    });
  }

  protected get fetchVariables(): CitiesQueryVariables {
    return {
      page: this.cities.paginatorInfo.currentPage,
      where: {
        OR: [
          {
            column: QueryCitiesWhereColumn.Name,
            value: `%${this.searchValue}%`,
            operator: SqlOperator.Ilike,
          },
        ],
      },
    } as CitiesQueryVariables;
  }

  @Debounce(500)
  protected search(): void {
    this.$apollo.queries.cities.refetch(this.fetchVariables);
  }
}
