
























































































































































































































































































































































import {
  Component,
  Prop,
  PropSync,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
import { Routes } from "@/types/core";
import {
  DoctorAssociation,
  DoctorPosition,
  DoctorSpecialty,
  DoctorWorkplace,
  ParticipantStatusEnum,
  User,
} from "@/generated/graphql";
import Table from "@components/parts/tables/Table.vue";
import { TableHeader } from "@/components/parts/tables/Settings.vue";
import {
  hospitalNames,
  participantStatuses,
  participantStatusNames,
} from "@/core/static/dict";

import Mailing from "@/components/parts/tables/Mailing.vue";

interface TypeField {
  id: number | string;
}

@Component({ components: { Table, Mailing } })
export default class UsersTable extends Vue {
  @Prop({ type: Array, default: () => [] }) items!: User[];
  @Prop({ type: Boolean, default: false }) isCanExportUser!: boolean;
  @Prop({ type: Boolean, default: false }) showSelect!: boolean;
  @Prop({ type: Boolean, default: false }) showStatus!: boolean;
  @Prop({ type: Boolean, default: false }) showStream!: boolean;
  @VModel({ type: Array, default: () => [] }) selected!: User[];
  @PropSync("searchValue", { type: String, default: "" }) search!: string;

  protected readonly Routes: typeof Routes = Routes;
  protected readonly hospitalNames = hospitalNames;
  protected readonly participantStatusNames = participantStatusNames;
  protected readonly participantStatuses = participantStatuses;

  protected filter: {
    id: string;
    fullname: string;
    role: string;
    associations: string;
    city: string;
    emails: string;
    phones: string;
    specialties: string;
    workplaces: string;
    positions: string;
    status: ParticipantStatusEnum | null;
    duration: any;
    first_connected_at: string;
    last_disconnected_at: string;
  } = {
    id: "",
    fullname: "",
    role: "",
    associations: "",
    city: "",
    emails: "",
    phones: "",
    specialties: "",
    workplaces: "",
    positions: "",
    status: null,
    duration: null,
    first_connected_at: "",
    last_disconnected_at: "",
  };

  protected visibleHeadersList: string[] = [];
  protected visibleHeaders: string[] = [];
  protected get headers(): TableHeader[] {
    return [
      { value: "id", text: "ID", width: 70, sortable: false },
      {
        value: "fullname",
        text: "ФИО пользователя",
        width: 200,
        sortable: false,
      },
      {
        value: "role",
        text: "Роль",
        sortable: false,
      },
      {
        value: "specialties",
        text: "Специальность",
        sortable: false,
      },
      {
        value: "positions",
        text: "Должность",
        hidden: true,
        width: 200,
        sortable: false,
      },
      {
        value: "workplaces",
        text: "Место работы",
        hidden: true,
        width: 200,
        sortable: false,
      },
      {
        value: "associations",
        text: "Ассоциация врачей",
        sortable: false,
      },
      {
        value: "city",
        text: "Город",
        sortable: false,
      },
      {
        value: "emails",
        text: "E-mail",
        sortable: false,
      },
      {
        value: "phones",
        text: "Телефон",
        sortable: false,
      },
      ...(this.showStatus
        ? [{ text: "Статус", value: "status", sortable: false, width: 200 }]
        : []),
      ...(this.showStream
        ? [
            {
              value: "duration",
              text: "Время на трансляции",
              sortable: false,
            },
          ]
        : []),
      ...(this.showStream
        ? [
            {
              value: "first_connected_at",
              text: "Время входа",
              sortable: false,
            },
          ]
        : []),
      ...(this.showStream
        ? [
            {
              value: "last_disconnected_at",
              text: "Время выхода",
              sortable: false,
            },
          ]
        : []),
      {
        text: "Действия",
        value: "_actions",
        sortable: false,
      },
    ];
  }

  protected selectedInternal: User[] = [];

  @Watch("selectedInternal")
  private syncInternalSelectedToExternal(): void {
    this.selected = this.selectedInternal;
  }

  @Watch("selected")
  private syncExternalSelectedToInternal(): void {
    this.selectedInternal = this.selected;
  }

  private mapDoctorProfile(user: User) {
    const _map = <T extends Array<{ name: string }>>(values: T): string[] => {
      return (values || []).map((v) => v.name);
    };
    const profile = user.doctorProfile;
    return {
      specialties: _map(profile?.specialties as DoctorSpecialty[]),
      workplaces: _map(profile?.workplaces as DoctorWorkplace[]),
      associations: _map(profile?.associations as DoctorAssociation[]),
      positions: _map(profile?.positions as DoctorPosition[]),
    };
  }

  private mapContacts(user: User) {
    return {
      emails: user.emails?.map((em) => em.value) || [],
      phones: user.phones?.map((ph) => ph.value) || [],
    };
  }

  private get exportResolvers() {
    return {
      fullname: (item: User) =>
        [item.family_name, item.first_name, item.second_name].join(" "),
      city: (item: User) => (item.city ? item.city.name : "-"),
      specialties: (item: User) =>
        this.mapDoctorProfile(item).specialties.join(", "),
      associations: (item: User) =>
        this.mapDoctorProfile(item).associations.join("\n"),
      positions: (item: User) =>
        this.mapDoctorProfile(item).positions.join(", "),
      workplaces: (item: User) =>
        this.mapDoctorProfile(item).workplaces.join(", "),
      emails: (item: User) => this.mapContacts(item).emails.join(", "),
      phones: (item: User) => this.mapContacts(item).phones.join(", "),
      role: (item: User) => item.roles?.map((r) => r.name).join(", "),
      status: (item: User) =>
        item.pivot?.participant_status &&
        //@ts-ignore
        this.participantStatusNames[
          item.pivot?.participant_status?.toUpperCase()
        ],
    };
  }

  /**
   * Геттер с отфильтрованными по колонкам строками
   * СТРОГОЕ сравнение AND
   * @protected
   */
  protected get filteredEvents(): User[] {
    const filter = this.filter;
    const conditions: CallableFunction[] = [];

    if (filter.id) conditions.push(this.filterId);
    if (filter.fullname) conditions.push(this.filterFullname);
    if (filter.role) conditions.push(this.filterRole);
    if (filter.specialties) conditions.push(this.filterSpecialties);
    if (filter.workplaces) conditions.push(this.filterWorkplaces);
    if (filter.associations) conditions.push(this.filterAssociations);
    if (filter.positions) conditions.push(this.filterPositions);
    if (filter.emails) conditions.push(this.filterEmails);
    if (filter.phones) conditions.push(this.filterPhones);
    if (filter.city) conditions.push(this.filterCity);
    if (filter.status) conditions.push(this.filterStatus);

    if (conditions.length > 0) {
      return this.items.filter((item) =>
        conditions.every((cond) => cond(item))
      );
    } else return this.items;
  }

  private filterableItemField<T extends TypeField>(
    acc: Array<T>,
    curr: T
  ): Array<T> {
    const itemAcc = acc;
    if (!acc.length || acc.findIndex((el: T) => +el.id === +curr.id) === -1) {
      itemAcc.push(curr);
    }
    return itemAcc;
  }

  private doctorProfileWorkplacesItem(item: User): Array<DoctorWorkplace> {
    return item?.doctorProfile?.workplaces
      ? item.doctorProfile.workplaces.reduce(
          (acc: Array<DoctorWorkplace>, curr: DoctorWorkplace) => {
            return this.filterableItemField(acc, curr);
          },
          []
        )
      : [];
  }

  private doctorProfileAssociationsItem(item: User): Array<DoctorAssociation> {
    return item?.doctorProfile?.associations
      ? item.doctorProfile.associations.reduce(
          (acc: Array<DoctorAssociation>, curr: DoctorAssociation) => {
            return this.filterableItemField(acc, curr);
          },
          []
        )
      : [];
  }

  private filterId(item: User): boolean {
    if (!this.visibleHeadersList.includes("id")) return true;
    return +item.id === +this.filter.id;
  }

  private filterFullname(item: User): boolean {
    if (!this.visibleHeadersList.includes("fullname")) return true;
    return [item.family_name, item.first_name, item.second_name]
      .join(" ")
      .toLowerCase()
      .includes(this.filter.fullname.toLowerCase());
  }

  private filterRole(item: User): boolean {
    if (!this.visibleHeadersList.includes("role")) return true;
    return !!item.roles
      ?.map((r) => r.name.toLowerCase())
      .join(" ")
      .includes(this.filter.role.toLowerCase());
  }

  private filterAssociations(item: User): boolean {
    if (!this.visibleHeadersList.includes("associations")) return true;
    return !!item.doctorProfile?.associations
      .map((s) => s.name.toLowerCase())
      .join(" ")
      .includes(this.filter.associations.toLowerCase());
  }

  private filterSpecialties(item: User): boolean {
    if (!this.visibleHeadersList.includes("specialties")) return true;
    return !!item.doctorProfile?.specialties
      .map((s) => s.name.toLowerCase())
      .join(" ")
      .includes(this.filter.specialties.toLowerCase());
  }

  private filterWorkplaces(item: User): boolean {
    if (!this.visibleHeadersList.includes("workplaces")) return true;
    return !!item.doctorProfile?.workplaces
      .map((w) => w.name.toLowerCase())
      .join(" ")
      .includes(this.filter.workplaces.toLowerCase());
  }
  private filterPositions(item: User): boolean {
    if (!this.visibleHeadersList.includes("positions")) return true;
    return !!item.doctorProfile?.positions
      .map((p) => p.name.toLowerCase())
      .join(" ")
      .includes(this.filter.positions.toLowerCase());
  }

  private filterCity(item: User): boolean {
    if (!this.visibleHeadersList.includes("city")) return true;
    return !!item.city?.name
      .toLowerCase()
      .includes(this.filter.city.toLowerCase());
  }

  private filterPhones(item: User): boolean {
    if (!this.visibleHeadersList.includes("phones")) return true;
    return !!item.phones
      ?.map((p) => p.value)
      .join(" ")
      .includes(this.filter.phones);
  }

  private filterEmails(item: User): boolean {
    if (!this.visibleHeadersList.includes("emails")) return true;
    return !!item.emails
      ?.map((e) => e.value)
      .join(" ")
      .toLowerCase()
      .includes(this.filter.emails.toLowerCase());
  }

  private filterStatus(item: User): boolean {
    if (!this.visibleHeadersList.includes("status")) return true;
    return (
      item.pivot?.participant_status?.toLowerCase() ===
      this.filter.status?.toLowerCase()
    );
  }
}
