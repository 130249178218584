



















import { Component, Vue, VModel, Prop } from "vue-property-decorator";
import { Role } from "@/generated/graphql";
import { Roles } from "@/graphql/queries/Roles.graphql";

@Component
export default class RolesAutocomplete extends Vue {
  @VModel({ type: Array, default: () => [] }) rolesList!: Role[];
  @Prop({ type: Boolean, default: false }) multiple!: boolean;
  @Prop({ type: Boolean, default: false }) returnObject!: boolean;

  protected search: string = "";
  private readonly query = Roles;
}
