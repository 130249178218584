var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloQuery',{attrs:{"query":_vm.query,"notify-on-network-status-change":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var data = ref_result.data;
var loading = ref_result.loading;
return [_c('v-autocomplete',_vm._g(_vm._b({attrs:{"label":"Должности","placeholder":"поиск","item-value":"id","item-text":"name","search-input":_vm.search,"items":!data
        ? []
        : _vm.employees
        ? data.AllEmployeesPositions
        : data.allDoctorsPositions,"loading":loading},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":function($event){_vm.search = ''}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'v-autocomplete',_vm.$attrs,false),_vm.$listeners))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }