




























import { Component, Vue, VModel, Prop } from "vue-property-decorator";
import { DoctorPosition, EmployeePosition } from "@/generated/graphql";
import { DocumentNode } from "graphql";
import { AllEmployeesPositions } from "@/graphql/queries/EmployeesPositions.graphql";
import { AllDoctorPositions } from "@/graphql/queries/DoctorsPositions.graphql";

@Component
export default class PositionsAutocomplete extends Vue {
  @Prop({ type: Boolean, default: false }) employees!: boolean;

  @VModel({ type: [String, Array] }) model!:
    | DoctorPosition
    | DoctorPosition[]
    | EmployeePosition
    | EmployeePosition[];

  protected search: string = "";
  protected get query(): DocumentNode {
    return this.employees ? AllEmployeesPositions : AllDoctorPositions;
  }
}
